<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Footer
  },

  beforeMount () {
    // wait a few seconds to allow fetching all the data
    // before emitting the prerender hook
    setTimeout(() => {
      document.dispatchEvent(new Event('x-app-rendered'))
    }, 2000)
  }
}
</script>

<style lang="scss">
@import './styles/_fonts.scss';
@import './styles/_grid.scss';
@import './styles/_elements.scss';
@import './styles/_helpers.scss';

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
