import baseMeta from './baseMeta'
import setMetaHelper from './setMetaHelper'

export function setMeta (to, extraMeta) {
  let meta = { ...baseMeta(), ...extraMeta }
  if (window.storeData) meta = JSON.parse(JSON.stringify(window.storeData))
  meta.title = `${to.name} | ${meta.title}`

  setMetaHelper(meta)
}

export function setMetaForMaskDetail (detail) {
  const localbaseMeta = baseMeta()
  const meta = {
    ...localbaseMeta,
    title: `${detail.title} | ${localbaseMeta.title}`,
    description: detail.description
  }

  setMetaHelper(meta)
}
