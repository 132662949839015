export default function baseMeta () {
  let baseMeta = {
    title: 'Clarity',
    description: 'Refining Your Experience Thru Skilled Guidance.',
    keywords: '',
    image: '/favicon.png'
  }

  if (window.storeData) baseMeta = { ...baseMeta, ...window.storeData }

  return baseMeta
}
