import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import { setMeta } from '@/meta/setMeta'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/discover',
    name: 'Discover',
    component: () => import(/* webpackChunkName: "about" */ '../views/Discover.vue')
  },
  {
    path: '/connect',
    name: 'Connect',
    component: () => import(/* webpackChunkName: "about" */ '../views/Connect.vue')
  },
  {
    path: '/style-guide',
    name: 'Style Guide',
    component: () => import(/* webpackChunkName: "about" */ '../views/StyleGuide.vue')
  },
  {
    path: '/about-app',
    name: 'About App',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutApp.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  setMeta(to)
  next()
})

export default router
