<template>
  <div class="home">
    <vue-background-image class="hero-header" :image-source="require('@/assets/hero.jpg')">
      <div class="hero-header-content max-width">
        <h1 class="uppercase">
          Focus Your Vision<br>
          Discover Your Potential<br>
          Unlock Your Future
        </h1>
        <br>
        <router-link class="button" to="/about">Learn More</router-link>
      </div>
      <div class="max-width home-content">
        <div>
          <h4>Executive<br> Coaching</h4>
        </div>
        <div>
          <h4>Leadership<br> Development</h4>
        </div>
        <div>
          <h4>Organizational<br> Consulting</h4>
        </div>
      </div>
    </vue-background-image>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style lang="scss" scoped>
.home-content {
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;

  > div {
    text-align: center;
    width: 100%;

    h4 {
      margin-bottom: 0;
      padding: 1.25rem 1.75rem;
      background: rgba($white, 0.75);
      margin-bottom: 1rem;
      text-transform: uppercase;

      br {
        display: none;
      }
    }

    p {
      margin: 0.5em 0 0;

      &:last-of-type {
        margin-bottom: 0 !important;
      }
    }
  }

  @media(min-width: $bp-s) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1em;
    flex-flow: row;
    justify-content: space-around;
    align-items: flex-end;
    padding: 2em 1em;

    h4 {
      padding: 2rem 1rem !important;
    }
  }

  @media(min-width: $bp-xl) {
    h4 {
      padding: 2rem 1.75rem !important;
    }
  }

  @media(min-width: $bp-s) and (max-width: $bp-l - 1) {
    br {
      display: block !important;
    }
  }
}
</style>
